import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { navigate } from "gatsby"

import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import Nav from '../components/Nav/Nav';
import VideoPlayer from '../components/Video/VideoPlayer/VideoPlayer';
import { GatsbyImage } from 'gatsby-plugin-image';

const Index = ({ data }) => {
  
  const dataHome = data?.contentfulHomePage;

  useEffect(()=>{
    if (window.innerWidth <= 620) {
      navigate('/creations/');
    }
  }, [])

  let is_show_visuel = false;
  if(!dataHome?.featuredCreation?.videoThumbnail?.file?.url && dataHome?.featuredCreation?.visuelThumbnail?.gatsbyImageData){
    is_show_visuel = true
  }
  return (
    <Layout>
      <Seo
        lang="fr"
        title={dataHome?.titleOfThePage}
        description={dataHome?.descriptionOfThePage}
      />
      <div id="main-wrapper" className="row">
        <Nav />
        <div className="home_content mt-6 col-md-12 col-lg-9">
          <section id="container" style={{ maxWidth: is_show_visuel? '700px' : '100%'}}>
            { dataHome?.featuredCreation?.videoThumbnail?.file?.url ?
                <VideoPlayer videoFileUrl={dataHome?.featuredCreation?.videoThumbnail?.file?.url} dataFullScreen={true} />
              :
                dataHome?.featuredCreation?.visuelThumbnail?.gatsbyImageData ? (
                  <>
                    {
                      dataHome?.featuredCreation.slug ? (
                        <Link to={`/creations/${dataHome?.featuredCreation.slug}`} aria-current="page">
                            <GatsbyImage layout="fullWidth" image={dataHome.featuredCreation.visuelThumbnail.gatsbyImageData} alt={dataHome.featuredCreation.visuelThumbnail.title} ></GatsbyImage>
                        </Link>
                      ) : 
                      (
                        <GatsbyImage layout="fullWidth" image={dataHome.featuredCreation.visuelThumbnail.gatsbyImageData} alt={dataHome.featuredCreation.visuelThumbnail.title} ></GatsbyImage>
                      )
                    }
                  </>
                )
                :
                <h2>PAS D'IMAGE</h2>
            }
            <div className="content-hp col-lg-12">
            <h2 className="title mt-4 mb-0">{dataHome?.featuredCreation?.creationDescription}</h2>
              <div className="discover">
                <Link to={`/creations/${dataHome?.featuredCreation.slug}`} aria-current="page">Discover</Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulHomePage {
      titleOfThePage
      descriptionOfThePage
      featuredCreation {
        visuelThumbnail {
          title
          gatsbyImageData
        }
        videoThumbnail {
          file {
            url
          }
        }
        slug
        creationDescription
      }
    }
  }
`

export default Index;
